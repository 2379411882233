<template>
    <div style="background:white" class="login d-flex justify-center align-center">
        <div class="login__containerForm">
            <div class="login__Logo home__title d-flex justify-center align-center">
                <img height="50px" src="/logos/logosmall.png" class="mr-2" />
                <h1 style="color:rgba(255,255,255,0.9)">GATONET</h1>
            </div>
            <v-form
                v-model="valid"
                v-on:submit.prevent="getIn()"
                lazy-validation
                ref="formlogin"
            >
                <TextFieldForm
                    class="mt-5 mb-2"
                    v-model="datesUser.username"
                    :clearable="true"
                    label="Username"
                    :counter="20"
                    :rules="[v => validateField(v) || 'El dato es requerido',
                        v => (v+'').length<=20 || 'El username no debe exeder los 20 dígitos',
                        v => (v+'').length>=10 || 'El username debe ser igual o mayor a 10 dígitos']"
                />
                <TextFieldForm
                    v-model="datesUser.contrasenia"
                    :clearable="true"
                    :type="showPassword ? 'text' : 'password'"
                    label="Contraseña"
                    :icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @clicksee="showPassword = !showPassword"
                    @search="getIn()"
                    :rules="[v => validateField(v) || 'El dato es requerido']"
                />
            </v-form>
            <div class="text-center">
                <v-btn
                    :loading="loadingBtn"
                    @click="getIn()"
                    depressed
                    dark
                    small
                    color="#071849"
                >INGRESAR</v-btn>
            </div>
        </div>
        <SmsError
                @click="dialogError=false"
                :dialog="dialogError"
                :smsError="smsError"
                :accion="accionError"
        />
    </div>
</template>
<script>
import { TextFieldForm } from '../../components/molecules'
import { SmsError } from '../../components/organisms'
import { rules, responseCase } from '../../mixins'
import { User } from '../../models'
import { mapState, mapMutations } from 'vuex'
export default {
    mixins: [rules, responseCase],
    components: {
        TextFieldForm,
        SmsError
    },
    data: () => ({
        dialogError: false,
        smsError: [],
        accionError: false,
        datesUser: {
            username: null,
            contrasenia: null
        },
        showPassword: false,
        valid: true,
        loadingBtn: false
    }),
    computed: {
        ...mapState('LoginStore', ['user'])
    },
    methods: {
        ...mapMutations('LoginStore', ['addHeader']),
        rulesDates() {
            var admisiondatos = true
            if (!this.$refs.formlogin.validate()) { admisiondatos = false }
            return admisiondatos
        },
        validateField(date) {
            return !this.emptyField(date)
        },
        processError(error) {
            if (this.autorization(error)) {
                if (this.disabledUser(error)) {
                    this.smsError = ['Su cuenta fue inhabilitada, porfavor comuniquese con el administrador']
                } else {
                    this.smsError = ['El username o la contraseña están incorrectos, porfavor revise sus datos']
                }
                this.accionError = true
            } else {
                this.smsError = ['Revise su conección de internet y refresque la página porfavor']
                this.accionError = false
            }
            this.dialogError = true
        },
        getIn() {
            if (this.rulesDates()) {
                this.loadingBtn = true
                User.authenticate(this.datesUser).then(response => {
                    localStorage.setItem('empresauser', JSON.stringify(response))
                    var header = {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": "Bearer " + response.api_token,
                    }
                    this.addHeader(header)
                    this.loadingBtn = false
                    this.$router.replace('/admin')
                }).catch((error) => {
                    this.loadingBtn = false
                    this.processError(error)
                })
            } else {
                this.smsError = ['Corrija los datos marcados con rojo y vuelva a intentarlo porfavor']
                this.accionError = true
                this.dialogError = true
            }
        }
    },
    mounted() {
        if (JSON.parse(localStorage.getItem('empresauser'))) {
            this.$router.replace('/admin')
        }
    }
}
</script>
<style scoped>
    .login{
        width: 100%;
        height: 100%;
        padding: 15px;
        background: rgb(232, 232, 233);
    }
    .login__containerForm{
        width: 380px;
        background: rgb(255, 255, 255);
        padding: 15px;
        border-radius: 10px;
        border: 1px solid rgb(197, 195, 195);
    }
    .login__Logo{
        width: 100%;
        height: 80px;
        background: rgb(17, 17, 17);
        border-radius: 5px;
    }
</style>